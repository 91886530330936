import { ICustomerLocation } from '@engineering11/customer-web'
import { ITimestamps } from '@engineering11/web-api-firebase'
import { IRequestedUpdate } from './candidate-application.model'
import { CandidateJobStatus, DegreeLevel, EmployeeLocation, ExperienceLevel, PayRateTypeEnum } from './enum'
import { IRequirableCertification, IRequirableSkill } from './interfaces'

export interface ICandidateJobVM extends Partial<ITimestamps> {
  id: string
  userId: string
  jobId: string
  status: CandidateJobStatus
  jobTitle: string
  companyLogoUrl?: string
  companyName: string
  locationAddress?: ICustomerLocation
  department?: string
  employeeLocation: EmployeeLocation
  employmentType?: string
  jobDescription?: string
  jobPaused?: boolean
  minimumQualifications?: string
  responsibilities?: string
  educationLevel: DegreeLevel
  scheduleText?: string
  // setHours: boolean
  travelPercentage?: number
  compensationLow?: number
  compensationHigh?: number
  benefits?: string
  payRateType?: PayRateTypeEnum
  requestedUpdated: IRequestedUpdate[]
  legalDisclaimer: string
  experienceLevel?: ExperienceLevel
  selectedSkills: IRequirableSkill[]
  selectedCertifications: IRequirableCertification[]
  appliedDate?: Date
  closedDate?: Date
  withdrawnDate?: Date
  /** Will determine if candidate needs to answer compliance-related questions */
  federalContractor?: boolean
}
