import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { E11ConfirmationComponent } from '@engineering11/ui-lib/e11-confirmation'
import { E11ErrorHandlerService, E11Logger, e11Error } from '@engineering11/web-api-error'
import { UserMode } from '../../../model/component-modes.enum'
import { IPortfolioDocument, IPortfolioFile } from '../../../model/portfolio.model'
import { PortfolioStore } from '../portfolio.store.component'

@Component({
  selector: 'portfolio-document-line-item',
  template: `
    <div class="e11-flex e11-items-center">
      <e11-file-viewer
        *ngIf="showFile && portfolioDocument?.file?.fileName && portfolioDocument!.file.signedURL"
        (close)="showFile = false"
        [name]="portfolioDocument!.file.fileName"
        [url]="portfolioDocument!.file.signedURL!"
      ></e11-file-viewer>

      <div
        class="e11-flex e11-w-full e11-p-4 e11-mb-4 e11-border e11-border-skin-app-borders e11-rounded-lg "
        [ngClass]="{
          'e11-cursor-pointer hover:e11-bg-skin-primary-accent/10 hover:e11-border-skin-primary-accent': portfolioDocument
        }"
      >
        <div class="e11-w-full">
          <ui-portfolio-reference-name
            [item]="portfolioDocument"
            placeholder="No document name"
            [userMode]="userMode"
            (updateReferenceName)="onUpdateReferenceName($event)"
          ></ui-portfolio-reference-name>
          <div (click)="openFile(portfolioDocument)" class="e11-flex e11-grow">
            <div class="e11-flex e11-grow e11-items-center e11-justify-start">
              <span class="e11-pr-2 material-icons-outlined"> description </span>
              <p class="e11-m-0 e11-line-clamp-1 e11-skeleton-empty">{{ portfolioDocument?.file?.fileName }}</p>
            </div>
            <div *ngIf="userMode === UserMode.Candidate" class="e11-flex e11-justify-end e11-items-center">
              <span
                *ngIf="portfolioDocument"
                class="material-icons-outlined e11-mr-2 e11-p-1 md-18 hover:e11-bg-skin-secondary/20 e11-text-skin-secondary hover:e11-rounded-full hover:e11-cursor-pointer"
                (click)="openConfirmation($event)"
                >delete</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <e11-confirmation
      #confirmation
      confirmTitle="{{ 'Delete this document' | translate }}"
      confirmText="{{ 'confirm' | translate }}"
      [closeOnEscape]="false"
      cancelText="{{ 'cancel' | translate }}"
      [backdropStyle]="'dark'"
      (answer)="confirmationAnswer($event)"
    >
      {{ 'Are you sure you want to remove this document? You can always add it back if you need to.' | translate }}
    </e11-confirmation>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortfolioDocumentLineItemComponent {
  @ViewChild('confirmation') confirmation!: E11ConfirmationComponent

  @Input() portfolioDocument?: IPortfolioDocument
  @Input() userMode = UserMode.Candidate
  @Output() deleteDocument = new EventEmitter()
  showFile = false

  UserMode = UserMode

  constructor(private logger: E11Logger, private portfolioStore: PortfolioStore, private errorHandler: E11ErrorHandlerService) {}

  openConfirmation(event: Event) {
    this.confirmation.open()
    event.stopPropagation() // prevent open document event from firing
  }

  confirmationAnswer(res: boolean) {
    if (res) this.deleteDocument.emit()
  }

  openFile(item?: IPortfolioFile) {
    if (!item?.file?.signedURL || !item?.file?.fileName) {
      this.errorHandler.handleError(e11Error(errorViewingFile(item)), { alertUser: true })
      return
    }
    this.showFile = true
    this.logger.log('PortfolioDocumentLineItemComponent openFile - ', { portfolioDocument: item })
  }
  onUpdateReferenceName(referenceName: string) {
    const updateFile: IPortfolioDocument = {
      ...this.portfolioDocument!,
      referenceName,
    }
    this.portfolioStore.onPortfolioItemUpdated(updateFile)
  }
}

const errorViewingFile = (portfolioItem?: IPortfolioFile) => ({
  title: 'There was an error viewing this file',
  type: 'portfolio/document-view-error',
  additionalData: portfolioItem,
})
