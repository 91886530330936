import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core'
import { PlayerSize } from '@engineering11/multimedia-web'
import { E11ModalGlobalService } from '@engineering11/ui-lib/e11-modal'
import { Subject, takeUntil } from 'rxjs'
import { UserMode } from '../../../model/component-modes.enum'
import { IPortfolio } from '../../../model/portfolio.model'

@Component({
  selector: 'portfolio-videos-preview',
  template: `
    <div class="e11-grid e11-grid-cols-1 md:e11-grid-cols-3 e11-gap-4 e11-items-center e11-mb-4">
      <ng-container *ngIf="signingFiles; else videos">
        <div *ngFor="let i of 3 | range" class="e11-skeleton-empty !e11-h-20"></div>
      </ng-container>

      <ng-template #videos>
        <ng-container *ngIf="showPreviews">
          <div *ngFor="let item of items | slice : 0 : limit; let i = index">
            <div (click)="openVideoPlayer(item)" class="e11-relative e11-bg-skin-app-bg-dark/50">
              <img [src]="item.thumb?.signedURL" class="e11-cursor-pointer e11-object-cover e11-w-full e11-h-[100px]" />
              <div class="e11-cursor-pointer e11-absolute e11-inset-0 e11-flex e11-items-center e11-justify-center e11-bg-black e11-opacity-50">
                <span class="material-icons e11-text-white e11-text-4xl hover:e11-text-gray-500">play_circle_outline</span>
              </div>
            </div>
            <div class="e11-flex e11-flex-col">
              <span class="e11-text-sm  e11-text-skin-primary-accent e11-truncate e11-font-bold">{{
                item.referenceName ? item.referenceName : item.file?.fileName
              }}</span>
            </div>
          </div>
        </ng-container>
      </ng-template>
    </div>

    <ng-template #videoPlayerModal>
      <div class="e11-h-full e11-w-full">
        <video-player *ngIf="videoPlayerResource" [playerSize]="PlayerSize.Small" [videoPlayerResource]="videoPlayerResource"></video-player>
      </div>
    </ng-template>

    <div *ngIf="showPreviews" class="e11-flex e11-justify-end">
      <p
        (click)="viewMoreClicked.emit()"
        *ngIf="items.length > limit"
        class="e11-mb-2 e11-text-xs e11-text-skin-primary-accent e11-text-end e11-cursor-pointer e11-w-fit"
      >
        {{ items.length - limit }} {{ 'more video(s)...' | translate }}
      </p>
    </div>
  `,
  styles: ``,
})
export class PortfolioVideosPreviewComponent implements OnInit, OnDestroy {
  @ViewChild('videoPlayerModal') videoPlayerModal!: TemplateRef<any>
  @Input() items: IPortfolio[] = []
  @Input() userMode: UserMode = UserMode.Candidate
  @Input() showPreviews: boolean = true
  @Input() signingFiles: boolean = true

  @Output() viewMoreClicked = new EventEmitter()
  destroy$: Subject<boolean> = new Subject<boolean>()
  PlayerSize = PlayerSize
  videoPlayerResource: { thumb?: string; video?: string } | undefined = undefined
  limit: number = 3

  constructor(private modalService: E11ModalGlobalService) {}

  ngOnInit() {
    if (this.userMode === UserMode.Public) {
      this.limit = this.items.length
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  openVideoPlayer(item: IPortfolio) {
    this.modalService.openModal({
      body: this.videoPlayerModal,
      width: 'xl',
      bodyHeight: 'large',
      closer: true,
      showHeader: false,
      showFooter: false,
    })

    setTimeout(() => {
      this.videoPlayerResource = {
        thumb: item.thumb?.signedURL,
        video: item.file?.signedURL,
      }
    })

    this.modalService.modalClosed$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.videoPlayerResource = {
        thumb: undefined,
        video: undefined,
      }
    })
  }
}
