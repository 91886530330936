import { Component, EventEmitter, Input, Output } from '@angular/core'
import { FormBuilder, FormControl } from '@angular/forms'

import { E11FormValidator } from '@engineering11/ui-lib/e11-input-errors'
import { UserMode } from '../../model/component-modes.enum'
import { IPortfolio } from '../../model/portfolio.model'

@Component({
  selector: 'ui-portfolio-reference-name',
  template: `
    <div class="e11-flex e11-flex-col" *ngIf="userMode === UserMode.Employer || userMode === UserMode.Public">
      <span class="e11-text-sm e11-font-bold e11-text-skin-primary-accent e11-truncate">{{
        portfolioItem?.referenceName ? portfolioItem?.referenceName : portfolioItem?.file?.fileName
      }}</span>
    </div>
    <form [formGroup]="form" *ngIf="userMode === UserMode.Candidate">
      <e11-inline-input
        [placeholder]="placeholder"
        [editIcon]="true"
        [size]="'sm'"
        [value]="f.referenceName.value ?? undefined"
        [parentForm]="form"
        [formControlName]="'referenceName'"
        (inputSaved)="onUpdateReferenceName()"
      ></e11-inline-input>
    </form>
  `,
})
export class PortfolioReferenceNameComponent {
  portfolioItem?: IPortfolio
  @Input() userMode = UserMode.Candidate
  @Input() placeholder: string = 'No name'
  @Input() set item(item: IPortfolio | undefined) {
    this.form.patchValue(item as IPortfolio)
    this.portfolioItem = item
  }
  @Output() updateReferenceName = new EventEmitter<string>()
  UserMode = UserMode
  form = this.fb.group({
    referenceName: new FormControl<string | undefined>(undefined, [E11FormValidator.whitespaceValidator()]),
  })
  get f() {
    return this.form.controls
  }
  constructor(private fb: FormBuilder) {}
  onUpdateReferenceName() {
    if (this.form.invalid) {
      this.form.reset()
    } else this.updateReferenceName.emit(this.f.referenceName.value!)
  }
}
