import { IFileReceipt } from '@engineering11/files-web'

export enum PortfolioItemType {
  FileUpload = 'FileUpload',
  Url = 'Url',
}

export enum PortfolioUiType {
  NotSupported = 'NotSupported',
  Photo = 'Photo',
  Document = 'Document',
  Video = 'Video',
  Link = 'Link',
}

export type PortfolioUiFileTypes = Exclude<PortfolioUiType, PortfolioUiType.Link | PortfolioUiType.NotSupported>

export interface IPortfolio {
  id: string
  itemType: PortfolioItemType
  thumb?: IFileReceipt
  url?: string
  referenceName?: string
  file?: IFileReceipt
}

export interface IPortfolioFile extends IPortfolio {
  file: IFileReceipt
  itemType: PortfolioItemType.FileUpload
}

export interface IPortfolioUrl extends IPortfolio {
  url: string
  itemType: PortfolioItemType.Url
}

export function portfolioUiTypeIsFile(uiType: PortfolioUiType): uiType is PortfolioUiFileTypes {
  const fileTypes = [PortfolioUiType.Document, PortfolioUiType.Photo, PortfolioUiType.Video]
  return fileTypes.includes(uiType)
}

// TODO: Consider a portfolio VM type which knows the PortfolioUiFileType

export interface IPortfolioDocument extends IPortfolioFile {}

export interface IPortfolioPhoto extends IPortfolioFile {}

export interface IPortfolioVideo extends IPortfolioFile {}

export interface IPortfolioLink extends IPortfolioUrl {}
