import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { E11NotificationsService } from '@engineering11/ui-lib/e11-notifications'
import { copyToClipboard } from '@engineering11/web-utilities'
import { UserMode } from '../../../model/component-modes.enum'
import { IPortfolio } from '../../../model/portfolio.model'

@Component({
  selector: 'portfolio-links-preview',
  template: `
    <div class="e11-mb-4">
      <div *ngFor="let link of portfolioLinks | slice : 0 : limit" class="e11-flex  e11-text-sm e11-gap-4 e11-items-center">
        <div class="e11-px-2">
          <a e11ExternalLink [href]="link.url!" [title]="'Click to view in a new browser tab' | translate" class="e11-text-skin-primary-accent">
            <span class="material-icons-outlined e11-mr-2 md-18 e11-cursor-pointer e11-text-skin-primary-accent"> launch </span>
          </a>

          <span
            (click)="copyLinkToClipboard(link.url!)"
            [title]="'Click to copy link to your clipboard' | translate"
            class="material-icons-outlined e11-mr-2 md-18 e11-cursor-pointer e11-text-skin-primary-accent"
          >
            copy
          </span>
        </div>
        <a
          e11ExternalLink
          target="_blank"
          [title]="'Click to view in a new browser tab' | translate"
          class="e11-text-skin-primary-accent"
          [href]="link.url"
          >{{ link.referenceName ? link.referenceName : link.url }}
        </a>
      </div>
    </div>

    <div class="e11-flex e11-justify-end">
      <p
        (click)="viewMoreClicked.emit()"
        *ngIf="portfolioLinks.length > limit"
        class="e11-mb-2 e11-text-xs e11-text-skin-primary-accent e11-text-end e11-cursor-pointer e11-w-fit"
      >
        {{ portfolioLinks.length - limit }} {{ 'more link(s)...' | translate }}
      </p>
    </div>
  `,
  styles: ``,
})
export class PortfolioLinksPreviewComponent implements OnInit {
  @Input() portfolioLinks: IPortfolio[] = []
  @Input() userMode: UserMode = UserMode.Candidate

  @Output() viewMoreClicked = new EventEmitter()

  limit: number = 3

  constructor(private notificationsService: E11NotificationsService) {}

  ngOnInit() {
    if (this.userMode === UserMode.Public) {
      this.limit = this.portfolioLinks.length
    }
  }

  async copyLinkToClipboard(url: string) {
    copyToClipboard(url)
    this.notificationsService.popNotificationMessage({
      title: 'Link copied',
      message: 'Link copied to clipboard',
      type: 'success',
      autoClose: true,
      dismissOnRouteChange: true,
    })
  }
}
