import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { UserMode } from '../../../model/component-modes.enum'
import { IPortfolioFile, IPortfolioPhoto, PortfolioUiType } from '../../../model/portfolio.model'
import { PortfolioStore } from '../portfolio.store.component'

@Component({
  selector: 'portfolio-photos',
  template: `
    <ng-container *ngrxLet="itemLoaded$ as itemLoaded">
      <ng-container *ngrxLet="signingFiles$ as signingFiles">
        <div class="e11-flex e11-justify-between e11-items-center e11-flex-wrap">
          <div *ngIf="userMode === UserMode.Candidate" class="e11-flex e11-gap-2 e11-items-center e11-mb-4">
            <span class="material-icons-outlined md-36">image</span>
            <p class="e11-font-primary-demibold e11-mb-0">
              {{ 'Add an image to your portfolio' | translate }}
            </p>
          </div>

          <div class="e11-flex e11-w-full md:e11-w-1/2 e11-justify-between">
            <p class="e11-font-primary-demibold e11-mb-6">{{ _portfolioPhotos.length }} {{ 'image(s)' | translate }}</p>
            <portfolio-clear [userMode]="userMode" [itemType]="PortfolioUiType.Photo" [portfolioItems]="_portfolioPhotos"></portfolio-clear>
          </div>
        </div>

        <div class="e11-grid e11-grid-cols-1 md:e11-grid-cols-3 e11-gap-4 e11-auto-rows-auto">
          <div *ngIf="userMode === UserMode.Candidate">
            <sdk-file-drop
              (newFile)="uploadPortfolioPhotos([$event])"
              [allowedFileTypes]="['image/*']"
              [dropInstruction]="'Drag and drop your image here' | translate"
              [loading]="!itemLoaded"
            ></sdk-file-drop>
          </div>

          <portfolio-upload-empty *ngIf="!_portfolioPhotos.length" [userMode]="userMode" [itemType]="PortfolioUiType.Photo"></portfolio-upload-empty>

          <portfolio-photo-line-item
            *ngFor="let image of _portfolioPhotos; let i = index"
            [image]="image"
            [userMode]="userMode"
            (deletePhoto)="deletePortfolioPhoto(image)"
            (viewClicked)="imageViewer.open(i)"
          ></portfolio-photo-line-item>

          <e11-image-viewer #imageViewer [imageUrl]="_portfolioPhotos | portfolioPhotoUrls"></e11-image-viewer>
        </div>
      </ng-container>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortfolioPhotosComponent {
  _portfolioPhotos: IPortfolioPhoto[] = []

  @Input() set portfolioPhotos(portfolioPhotos: IPortfolioPhoto[] | undefined) {
    this._portfolioPhotos = portfolioPhotos ?? []
  }

  @Input() userMode = UserMode.Candidate

  @Output() closeModal = new EventEmitter()

  itemLoaded$ = this.portfolioStore.itemLoaded$
  signingFiles$ = this.portfolioStore.signingFiles$

  UserMode = UserMode

  PortfolioUiType = PortfolioUiType

  constructor(private portfolioStore: PortfolioStore) {}

  async ngOnChanges() {
    if (this._portfolioPhotos) this.portfolioStore.signFiles(this._portfolioPhotos)
  }

  async uploadPortfolioPhotos(files: File[]) {
    this.portfolioStore.uploadPortfolioFiles(files)
  }

  async deletePortfolioPhoto(item: IPortfolioFile) {
    this.portfolioStore.deletePortfolioFile(item)
  }
}
